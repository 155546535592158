.home-banner {
    background-image: url('../img/Home/MJM-Heavy-Equipment-Repairs.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 194px;
    padding-bottom: 40px;
    height: 100vh;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    // TODO mobile layout
}

.inner-page-banner {
    margin-top: 4%;
    background-image: url('../img/MJM-Heavy-Equipment-Repairs-inner-header.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    // padding-top: 194px;
    // padding-bottom: 40px;
    height: 50vh;

    @media (max-width: 991px) {
        position: relative;
        height: 30vh;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.45);
            height: 100%;
            width: 100%;
            z-index: 1;
        }
    }
}

.hover-expander.card {
    height: 500px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    .card-img-overlay {
        height: 112px;
        background-color: rgba($color: $primary, $alpha: 0.9);
        top: auto;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        display: flex;
        align-items: end;
        transition: all 0.3s ease;
        // &:hover{
        //     height: 100%;
        //     border-top-left-radius: 0.625rem;
        //     border-top-right-radius: 0.625rem;
        // }
    }

    &:hover {
        .card-img-overlay {
            height: 100%;
            border-top-left-radius: 0.625rem;
            border-top-right-radius: 0.625rem;
        }
    }

}


@media (max-width:1024px) {
    .hover-expander.card {
        height: 355px;
    }
}

@media (max-width: 991px) {
    .hover-expander.card {
        height: 140px;
        background-size: cover;



        .card-img-overlay {
            height: 140px;
            border-top-left-radius: 0.625rem;
            border-top-right-radius: 0.625rem;
            text-align: center;
            justify-content: center;
            align-items: center;

            .card-title {
                font-weight: bold;
            }
        }

        &:hover {
            .card-img-overlay {}
        }
    }
}

hr {
    &.gradient {
        background: linear-gradient(90deg, rgba(242, 101, 49, 0.00) 0%, #F26531 16.67%, rgba(242, 101, 49, 0.00) 100%);
        height: 1px;
        opacity: 1;
        border-top: none;
    }

    &.dark-gradient {
        background: linear-gradient(90deg, $secondary 0%, #F26531 50%, $secondary 100%);
        height: 1px;
        opacity: 1;
        border-top: none;
    }

    &.solid {
        height: 3px;
        opacity: 1;
        border-top: none;

        &.solid-1 {
            background: #F26531;
        }

        &.solid-2 {
            background: #FF891C;
        }

        &.solid-3 {
            background: #EF8E3C;
        }

        &.solid-4 {
            background: #FFC386;
        }
    }
}


.contour-bg {
    background-image: url('../img/contour.svg');
    background-repeat: repeat;
    background-size: cover;
}

.social-link {
    &:hover {
        fill: $primary;
    }
}

.grid-area-container {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "grid-area-1 grid-area-2"
        "grid-area-1 grid-area-3";

    @media (max-width:768px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "grid-area-2"
            "grid-area-1"
            "grid-area-3";
    }

    &.grid-area-image-right {
        grid-template-areas:
            "grid-area-1 grid-area-3"
            "grid-area-2 grid-area-3";

        @media (max-width:768px) {
            grid-template-areas:
                "grid-area-1"
                "grid-area-3"
                "grid-area-2";
        }
    }
}



.grid-area-1 {
    grid-area: grid-area-1;
}

.grid-area-2 {
    grid-area: grid-area-2;
}

.grid-area-3 {
    grid-area: grid-area-3;
}