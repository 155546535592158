@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

// https://github.com/twbs/bootstrap/blob/v5.3.3/scss/_variables.scss
// Colors
$primary:       #F26531;
$secondary:     #161E29;
$light:         #EFEFE9;
$danger:        #EB0000;
$dark:          #161E29;
$white:         #FFFFFF;
$gray:          #7C7C7C;
$light-gray:    #F8F8FA;
$gray-100:      #F8F7F5;
$success:       #80C342;
$info:          #009CDE;
$warning:       #FFCF01;
$secondary-gradient: linear-gradient(1deg, rgba($secondary, 0.60) 22.21%, rgba($secondary, 0) 81.41%);
$body-color: $dark;
$font-size-root:              1rem;
$font-size-base:              0.875rem;
$font-size-xs:                $font-size-base *0.6875;
$font-size-sm:                $font-size-base * 0.75;
$small-font-size:             $font-size-base * 0.9375;
$font-size-lg:                $font-size-base * 1.252;
$enable-rfs: true;
$enable-negative-margins: true;
$enable-rounded: true;
$border-radius: 0.625rem;//10

$font-family-base:"Inter", sans-serif;




$font-weight-lighter:         lighter;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder;

$line-height-base:            1.625;
$line-height-sm:              1.25 !default;
$line-height-lg:              2.25;
$line-height-md:              1.33;

// scss-docs-start type-variables
$lead-font-size:              1.25rem;
$lead-font-weight:            300;

$paragraph-margin-bottom:0;

//Headings 
$headings-color:     $dark;
$headings-margin-bottom: 0;
$headings-font-family: "Inter", sans-serif;
$headings-font-weight: 300;
$h1-font-size: 2.5rem;//40
$h2-font-size: 2.25rem;//36
$h3-font-size: 1.875rem;//30
$h4-font-size: 1.625rem;//26
$h5-font-size: 1.25rem;//20
$h6-font-size: 1rem;//16
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $font-size-sm,
  8: $font-size-xs
);
$display-font-sizes: (
  1: 2.875rem,//46
  2: 2.625rem,//42
  3: $h4-font-size,//26
  4: 1.375rem,//22
  5: 1rem,//16
  6: 0.875rem, //14
);
$display-font-weight: 700;
// $display-line-height: 1.1;
$display-line-height: 1.5;


// reboot
$hr-margin-y: 0;
$hr-border-color: rgba(#a5a5a5, 0.3);


// scss-docs-start theme-bg-subtle-variables
$primary-bg-subtle:       tint-color($primary, 80%);
$secondary-bg-subtle:     tint-color($success, 80%);
// $success-bg-subtle:       tint-color($success, 80%) !default;
// $info-bg-subtle:          tint-color($info, 80%) !default;
// $warning-bg-subtle:       tint-color($warning, 80%) !default;
// $danger-bg-subtle:        tint-color($danger, 80%) !default;
$light-bg-subtle:         #f5f5f5;
// $dark-bg-subtle:          $gray-400 !default;

// btns
$btn-padding-y:                   0.938rem;
$btn-padding-x:                   1.5rem;
$btn-font-size:                   0.938rem;
$btn-line-height:                 1.5;
$btn-font-weight:                 500;
$btn-border-radius:               0.375rem;

$btn-padding-y-sm:            0.5rem;
$btn-padding-x-sm:            1.5rem;
$btn-font-size-sm:            0.938rem;


$spacers: (
  0: 0,
  1: 1px,
  2: 0.125rem,//2px
  4: 0.25rem,//4px
  6: 0.375rem,//6px
  8: 0.5rem	,//8px
  10: 0.625rem,//10px
  12: 0.75rem,//12px
  14: 0.875rem,//14px
  16: 1rem,//16px
  20: 1.25rem,//20px
  24: 1.5rem,//24px
  28: 1.75rem,//28px
  32: 2rem,//32px
  36: 2.25rem,//36px
  40: 2.5rem,//40px
  42: 2.63rem,
  44: 2.75rem,
  48: 3rem,
  56: 3.5rem,
  64: 4rem,
  70: 4.37rem,
  74: 4.625rem,
  90: 5.625rem,
  98: 6.12rem,
  112: 7rem,
  120: 7.5rem,
  128: 8rem,
  130: 8.625rem,
  144: 9rem,
  150: 9.55rem,
  160: 10rem,
  171: 10.725rem,
  176: 11rem,
  180: 11.55rem,
  192: 12rem,
  208: 13rem,
  224: 14rem,
  240: 15rem,
  256: 16rem,
  288: 18rem,
  320: 20rem,
  384: 24rem,
  448: 28rem,
  512: 32rem,
  576: 36rem,
  640: 40rem

);

//Nav 
$nav-link-font-weight:              500;
$nav-link-color:                   $white;
$nav-link-hover-color:             $secondary;
$navbar-dark-color:                 $white;
$navbar-dark-hover-color:           $white;
$navbar-dark-active-color:          $white;
$navbar-padding-y: 0rem;
$nav-link-padding-y:         1.375rem;
// $navbar-padding-x: 2.5rem;
// $nav-link-padding-x:                2.5rem;
// $navbar-nav-link-padding-x:         2.5rem;
// $nav-link-font-weight:              700;
// $navbar-dark-color:                 $white;
// // $navbar-dark-brand-color:        #fff;
// // $navbar-dark-active-color:       #fff;
// $dropdown-spacer:                   0;

$nav-link-font-size:                1rem;
$dropdown-font-size:                1rem;
$dropdown-border-width:             0;
$dropdown-item-padding-y: 12px;
$dropdown-link-hover-bg:            transparent;
$dropdown-link-active-color:        $primary;
$dropdown-link-active-color:        $primary;
$dropdown-link-active-bg:           transparent;

// $navbar-brand-font-size:            $font-size-sm;
// $dropdown-color:                    $white;
// $dropdown-bg:                       $primary;
// $dropdown-link-color:               $white;

// $navbar-toggler-padding-y:          0;
// $navbar-toggler-padding-x:          0;
$navbar-toggler-border-color: none;
$navbar-light-icon-color:$light;
$navbar-dark-icon-color:$secondary;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='26' height='23' viewBox='0 0 26 23' fill='none'><path d='M0 1H26' stroke='#{$navbar-light-icon-color}' stroke-width='1.5'/><path d='M8.04761 11L25.3809 11' stroke='#{$navbar-light-icon-color}' stroke-width='1.5'/><path d='M14.2381 22L25.381 22' stroke='#{$navbar-light-icon-color}' stroke-width='1.5'/></svg>");

// Offcanvas
$offcanvas-vertical-height:         100vh !default;
$offcanvas-bg-color:                $primary;
$offcanvas-color:                   $light;

// Close
// scss-docs-start close-variables
$btn-close-width:            1.45em !default;
$btn-close-color:            $light;

//accordion
$accordion-padding-x: 0;
$accordion-button-active-bg:  transparent;
$accordion-button-active-color: $secondary;
$accordion-icon-color: $primary;

$accordion-button-icon:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 12" fill="none"><path d="M0.0234375 7.33597V4.76797H4.53544V0.615967H7.15144V4.76797H11.6394V7.33597H7.15144V11.488H4.53544V7.33597H0.0234375Z" fill="#00008B"/></svg>');
$accordion-button-active-icon:  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 4" fill="none"><path d="M0.639648 3.33607V0.768066H10.6956V3.33607H0.639648Z" fill="#80C342"/></svg>');
// $accordion-button-icon:         url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><circle cx="15" cy="15" r="15" fill="#CF0000"/><path d="M15 22.6369V7.36337V22.6369Z" fill="#CF0000"/><path d="M15 22.6369V7.36337" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/><path d="M22.6368 15.0001H7.36328H22.6368Z" fill="#CF0000"/><path d="M22.6368 15.0001H7.36328" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/></svg>');
// $accordion-button-active-icon:         url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><circle cx="15" cy="15" r="15" fill="#CF0000"/><path d="M9.6001 20.4L20.4001 9.59998L9.6001 20.4Z" fill="#CF0000"/><path d="M9.6001 20.4L20.4001 9.59998" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/><path d="M20.4001 20.4L9.6001 9.59998L20.4001 20.4Z" fill="#CF0000"/><path d="M20.4001 20.4L9.6001 9.59998" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/></svg>');




//breadcrumbs
$breadcrumb-divider-color:          $white;
$breadcrumb-active-color:           $white;
$breadcrumb-font-size:              $font-size-xs;
$breadcrumb-margin-bottom:          0;

//card
$card-border-color: none;
// $card-border-width:0;


//accordian
$accordion-border-width:           0;
$accordion-icon-color: $secondary;
$accordion-icon-active-color:$secondary;
$accordion-button-focus-box-shadow: none;
$accordion-button-icon: url("data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='24'%20height='24'%20viewBox='0%200%2024%2024'%20fill='none'%3E%3Crect%20width='24'%20height='24'%20fill='white'/%3E%3Cpath%20d='M10.5545%2019.6153V5.21191H13.4203V19.6153H10.5545ZM4.79102%2013.8412V10.9754H19.1944V13.8412H4.79102Z'%20fill='%23161E29'/%3E%3C/svg%3E");
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='24'%20height='24'%20viewBox='0%200%2024%2024'%20fill='none'%3E%3Cpath%20d='M11%2013.841V10.9752L13.4203%2010.9753V13.841L11%2013.841ZM4.79102%2013.841V10.9753H19.1944V13.841H4.79102Z'%20fill='%23161E29'/%3E%3C/svg%3E");


//Form 
$input-placeholder-color: $light;
$input-border-width:1px;
$input-border-color: #B0B0B0;
$input-bg: $white;
$form-label-color: #000;
$link-decoration: none;
$input-padding-y: 1rem;
$input-focus-box-shadow: .125rem;
$form-select-box-shadow: none;
$input-placeholder-color:#727173;