.required-label{
    position: relative;
    &::after{
        content:'*';
        color:$secondary;
        font-size: $font-size-sm;
        position: absolute;
        right:-15%;
        top:0;
    }
}
