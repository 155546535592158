.navbar-dark {
    background-color: transparent;
    color: $white;
    --bs-navbar-color: $white;
    --bs-navbar-hover-color: $secondary;
    --bs-navbar-toggler-light-bg: url("data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2226%22%20height=%2223%22%20viewBox=%220%200%2026%2023%22%20fill=%22none%22%3E%3Cpath%20d=%22M0%201H26%22%20stroke=%22%23161E29%22%20stroke-width=%221.5%22/%3E%3Cpath%20d=%22M8.04761%2011L25.3809%2011%22%20stroke=%22%23161E29%22%20stroke-width=%221.5%22/%3E%3Cpath%20d=%22M14.2381%2022L25.381%2022%22%20stroke=%22%23161E29%22%20stroke-width=%221.5%22/%3E%3C/svg%3E");
    box-shadow: 1px 1px rgba(var(--bs-body-bg-rgb), 0.4);

    svg {
        fill: $white;
    }

    &.inner-page-nav{
        box-shadow: none;
    }
    &.scrolled{
        box-shadow: 0 10px 45px 0 rgba(0, 0, 0, 0.06);
    }

    &.scrolled,
    &.inner-page-nav {
        background-color: $white;
        color: $dark;
        --bs-navbar-color: $dark;
        --bs-navbar-active-color: $dark;
        position: fixed;
        top: 0;

        .tel-icon {
            fill: $secondary;
        }

        svg {
            fill: #727173;
            .logo-primary{
                fill:$primary;
            }
        }

        .navbar-toggler-icon {
            background-image: var(--bs-navbar-toggler-light-bg);
        }
    }

}

.navbar {
    a.link {
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }

}

.nav-link {
    border-bottom: 3px solid transparent;

    &:hover,
    &.active {
        border-color: #FF891C;
    }

}

.navbar-toggler {
    border: none;

    &:focus {
        box-shadow: none;
    }
}

// .navbar-collapse{
//     margin-top: -4px;
// }

@media (max-width: 991px) {
    .navbar-expand-lg .navbar-collapse {
        background: white;
        position: fixed;
        background: white;
        width: 100%;
        left: 0px;
        top: 74px;
        bottom: 0px;
        text-align: center;
        color: $secondary;
        &.show {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

        }

        .nav-link,
        .dropdown-item {
            border-bottom-width: 0;
            font-size: $h4-font-size;

            &:hover,
            &.active {
                color: #FF891C;
            }
        }

        .dropdown-item {
            text-align: center;
            font-weight: var(--bs-nav-link-font-weight);
        }

        .link {
            border: 1px solid $primary;
            margin-top: 4.88rem;
            padding: .62rem 3.88rem;
            border-radius: $border-radius;
            margin-bottom: 0.75rem;
            // font-size: $small-font-size;

            .tel-icon {
                fill: $secondary;
            }
        }

    }
}


.navbar-nav {
    .dropdown-menu {
        left: -31px;
        margin-top: 10px;
        box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.15);
        &:after {
            content: '';
            position: absolute;
            left: 50%;
            margin-left: -10px;
            top: -10px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #fff;
        }
    }
}



// .menu-nav {
// 	font-weight: 400;
// 	font-size: 18px;
// 	line-height: 50%;
// 	margin-right: 15px;
// 	position: absolute;
// 	right: 42px;
// 	top: 50%;
// 	color: $light;

// 	@media (max-width: 768px) {
// 		display: none;
// 	}
// }

// .outer-menu {
// 	position: relative;
// }

// .outer-menu .checkbox-toggle {
// 	position: relative;
// 	top: 0;
// 	right: 0px;
// 	z-index: 2;
// 	cursor: pointer;
// 	width: 60px;
// 	height: 60px;
// 	opacity: 0;
// }

// .outer-menu .checkbox-toggle:checked+.hamburger>div {
// 	transform: rotate(135deg);
// }

// .outer-menu .checkbox-toggle:checked+.hamburger>div:before,
// .outer-menu .checkbox-toggle:checked+.hamburger>div:after {
// 	top: 0;
// 	transform: rotate(90deg);
// }

// .outer-menu .checkbox-toggle:checked+.hamburger>div:after {
// 	opacity: 0;
// }

// .outer-menu .checkbox-toggle:checked~.menu {
// 	pointer-events: auto;
// 	visibility: visible;
// }

// .outer-menu .checkbox-toggle:checked~.menu>div {
// 	transform: scale(1.5);
// 	transition-duration: 0.75s;
// 	@media (max-width:768px) {
// 		transform: scale(1.2);
//     }
// }

// .outer-menu .checkbox-toggle:checked~.menu>div>div {
// 	opacity: 1;
// 	transition: opacity 0.4s ease 0.4s;
// }

// .outer-menu .checkbox-toggle:checked:hover+.hamburger>div {
// 	transform: rotate(225deg);
// }

// .outer-menu .hamburger {
// 	position: absolute;
// 	top: 50%;
// 	right: 0;
// 	z-index: 1;
// 	width: 26px;
// 	background: transparent;
// 	border-radius: 0 0.12em 0.12em 0;
// 	cursor: pointer;
// 	transition: box-shadow 0.4s ease;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .hamburger>div {
// 	position: relative;
// 	flex: none;
// 	width: 100%;
// 	height: 2px;
// 	background: $light;
// 	transition: all 0.4s ease;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .hamburger:hover .hamburger-icon {
// 	background: $light;
// }

// .outer-menu .hamburger>div.hamburger-icon.add-white,
// .outer-menu .hamburger>div.hamburger-icon.add-white:before,
// .outer-menu .hamburger>div.hamburger-icon.add-white:after {
// 	background: $light;
// }

// .outer-menu .hamburger>div:before,
// .outer-menu .hamburger>div:after {
// 	content: "";
// 	position: absolute;
// 	z-index: 1;
// 	top: -7px;
// 	left: 0;
// 	width: 100%;
// 	height: 2px;
// 	background: inherit;
// 	transition: all 0.4s ease;
// }

// .outer-menu .hamburger>div:after {
// 	top: 7px;
// }

// .outer-menu .menu {
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	pointer-events: none;
// 	visibility: hidden;
// 	overflow: hidden;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	outline: 1px solid transparent;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .menu>div {
// 	width: 200vw;
// 	height: 200vw;
// 	color: $light;
// 	background: $primary;
// 	border-radius: 50%;
// 	transition: all 0.4s ease;
// 	flex: none;
// 	transform: scale(0);
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	overflow: hidden;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .menu>div>div {
// 	text-align: center;
// 	max-width: 90vw;
// 	max-height: 100vh;
// 	opacity: 0;
// 	transition: opacity 0.4s ease;
// 	overflow-y: auto;
// 	flex: none;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .menu>div>div>ul {
// 	list-style: none;
// 	padding: 0 1em;
// 	margin: 0;
// 	display: block;
// 	max-height: 100vh;
// 	overflow: hidden;

// }

// .outer-menu .menu>div>div>ul>li {
// 	padding: 0;
// 	margin: .5em;
// 	font-size: 30px;
// 	font-weight: 300;
// 	display: block;
// 	color: $white;
// }

// .outer-menu .menu>div>div>ul>li>a {
// 	position: relative;
// 	display: inline;
// 	cursor: pointer;
// 	transition: color 0.4s ease;
// }

// .outer-menu .menu>div>div>ul>li>a:hover:after {
// 	width: 100%;
// }