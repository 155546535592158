@import './variables';

//buttons & links
.btn-icon-left {
    display: inline-grid;
    grid-template-columns: 25px 1fr;
    grid-column-gap: 12px;
    align-items: center;
    transition: all .3s ease-in-out;

    img {
        width: 12px;
        transition: all .3s ease-in-out;
    }
}

.btn-icon-right {
    display: inline-grid;
    grid-template-columns: 1fr 12px;
    grid-column-gap: 12px;
    align-items: center;
    transition: all 0.2s ease;

    &:hover {
        grid-column-gap: 18px;
    }
}

.btn-primary {
    --bs-btn-color: #{$white};
    --bs-btn-bg: #{$primary};
    --bs-btn-border-color: #{$primary};
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: #FF891C;
    --bs-btn-hover-border-color: #FF891C;
    --bs-btn-focus-shadow-rgb: 38, 38, 156;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #FF891C;
    --bs-btn-active-border-color: #FF891C;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #{$primary};
    --bs-btn-disabled-border-color: #{$primary};
}

.btn-light {
    --bs-btn-color: #{$dark};
    --bs-btn-bg: #{$white};
    --bs-btn-border-color: #{$white};
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: #FF891C;
    --bs-btn-hover-border-color: #{$primary};
    --bs-btn-focus-shadow-rgb: 213, 212, 211;
    --bs-btn-active-color: #{$primary};
    --bs-btn-active-bg: #FF891C;
    --bs-btn-active-color: #{$white};
    --bs-btn-active-border-color: #{$primary};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #{$primary};
    --bs-btn-disabled-bg: #{$white};
    --bs-btn-disabled-border-color: #{$white};

    &:hover,&:active{
        .btn-icon{
            fill:$dark;
        }
    }
}


.btn-outline-light {
    --bs-btn-color: #{$white};
    --bs-btn-border-color: #{$white};
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #{$white};
    --bs-btn-hover-border-color: #{$white};
    --bs-btn-focus-shadow-rgb: 239, 239, 233;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #{$white};
    --bs-btn-active-border-color: #{$white};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
    --bs-btn-disabled-color: #{$white};
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #{$white};
    --bs-gradient: none;

    &:hover,&:active{
        .btn-icon{
            fill:$dark;
        }
    }
}



// This is gross but bootstrap is using important so can't modify there stuff. 
.primary-link {
    color: $primary;
    text-decoration-color: $primary;
    fill: $secondary;
    stroke: $secondary;

    &:hover,
    &:focus {
        color: $secondary;
        text-decoration-color: $secondary;
        fill: $secondary;
        stroke: $secondary;
    }
}
.light-link {
    color: $white;
    text-decoration-color: $white;
    fill: $white;
    stroke: $white;

    &:hover,
    &:focus {
        color: $secondary;
        text-decoration-color: $secondary;
        fill: $secondary;
        stroke: $secondary;
    }
}
