.accordion-button{
    *{
        color: inherit;
    }
}
// .accordion-button {
//     color: $primary;
//     font-size: $h5-font-size;
//     font-weight: 600;
//     font-family: $headings-font-family;

//     &:not(.collapsed) {
//         color: var(--#{$prefix}accordion-active-color);
//         background-color: var(--#{$prefix}accordion-active-bg);
//         box-shadow: inset 0 calc(-1 * var(--#{$prefix}accordion-border-width)) 0 var(--#{$prefix}accordion-border-color); // stylelint-disable-line function-disallowed-list

//         &::after {
//             background-image: var(--#{$prefix}accordion-btn-active-icon);
//             transform: var(--#{$prefix}accordion-btn-icon-transform);
//         }
//     }

//     // Accordion icon
//     &::after {}

//     &:hover {
//         z-index: 2;
//     }

//     &:focus {
//         box-shadow: none;
//         color: $secondary;
//     }
// }


// .accordion-body{
//     font-size: 0.8125rem;
// }