

.letter-spacing-lg{
    letter-spacing: 0.25rem;
}

.letter-spacing-sm{
    letter-spacing: 0.05rem;
}
.text-light{
    fill: $light;
}

.lh-md{
    line-height: $line-height-md;
}