// Include any default variable overrides here (though functions won't be available)
@import './variables';
@import './type';
@import "../../node_modules/bootstrap/scss/bootstrap.scss";


@import './navbar';
@import './nav';
@import './utilities';
@import './buttons';
@import './slider';
@import './accordion';
@import './form';
@import './animate';
@import './icons';
@import './breadcrumb';
@import './dropdown';
// @import '../../node_modules/swiper/swiper.css';
// @import 'swiper/css/navigation';
// @import 'swiper/css/pagination';
// import Swiper and modules styles
// @import '../../node_modules/swiper/swiper.scss';

// Then add additional custom code here
@import './custom';






// // .text-light-grey{
// //     color:$light-grey;
// // }

// .text-darkest-grey{
//     color: $gray-900;
// }
// // svg icons
// // .icon-light-grey{
// //     filter:invert(0.2);
// // }

// //Links
// .link{
//     text-decoration: none;
//     text-transform: capitalize;
//     color:$gray;
//     font-weight: 700;

// }



// .dropdown:hover>.dropdown-menu {
//     display: block;
//   }
  

// .top-nav{
//     a,p{
//         font-size: $font-size-sm;
//     }
// }



// .sponsers-header-slider{
//     .slider-img-container{
//         height: 9rem;
//         background-size: cover;
//         background-position: center;
//         background-repeat: no-repeat;
//     }

//     .slider-img-container-mob{
//         height: 4rem;
//         background-size: cover;
//         background-position: top;
//         background-repeat: no-repeat;
//     }
// }






// //nav pills
// .nav-pills{
//     .nav-link{
//         font-size: 1.75rem;
//         &.active{
//             border-bottom-color:$secondary;
//         }
//         @media (max-width: 768px) {
//             font-size: 1rem;
//         }
//     }

// }

// Form
.g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}

//Details page
.wysiwyg-content{
    p,h1,h2,h3,h4,h5,h6{
        margin-bottom: 2rem;
    }

    img{
        border-radius: var(--bs-border-radius);
    }
}
